import classNames from 'classnames';

import { ColorValues } from '@/interfaces/colors';

import AddIcon from '../../assets/images/add-no-fill.svg?react';
import AleloIcon from '../../assets/images/alelo.svg?react';
import AmexIcon from '../../assets/images/amex.svg?react';
import BackIcon from '../../assets/images/back.svg?react';
import BookIcon from '../../assets/images/book.svg?react';
import CabalIcon from '../../assets/images/cabal.svg?react';
import CalendarIcon from '../../assets/images/calendar.svg?react';
import CheckTransparentIcon from '../../assets/images/check-transparent.svg?react';
import CheckIcon from '../../assets/images/check.svg?react';
import ChevronDownIcon from '../../assets/images/chevron-down.svg?react';
import ChevronIcon from '../../assets/images/chevron-right.svg?react';
import ClipIcon from '../../assets/images/clip.svg?react';
import ClipboardIcon from '../../assets/images/clipboard.svg?react';
import ClockIcon from '../../assets/images/clock.svg?react';
import CloseIcon from '../../assets/images/close.svg?react';
import CommandedIcon from '../../assets/images/commanded.svg?react';
import CopyIcon from '../../assets/images/copy.svg?react';
import EmptyStateIcon from '../../assets/images/customers/not-found.svg?react';
import DeleteIcon from '../../assets/images/delete.svg?react';
import EditIcon from '../../assets/images/edit.svg?react';
import EloIcon from '../../assets/images/elo.svg?react';
import EraserIcon from '../../assets/images/eraser.svg?react';
import WarningIcon from '../../assets/images/exclamation-close.svg?react';
import InformativeIcon from '../../assets/images/exclamation-open.svg?react';
import ExpandIcon from '../../assets/images/expand.svg?react';
import ExportIcon from '../../assets/images/export.svg?react';
import FastForwardIcon from '../../assets/images/fast-forward.svg?react';
import FilterIcon from '../../assets/images/filter.svg?react';
import FiltersLinesIcon from '../../assets/images/filters-lines.svg?react';
import HipercardIcon from '../../assets/images/hipercard.svg?react';
import ImportIcon from '../../assets/images/import.svg?react';
import InProgressDishIcon from '../../assets/images/inprogress-dish.svg?react';
import LinkIcon from '../../assets/images/link.svg?react';
import MasterIcon from '../../assets/images/master.svg?react';
import MessageIcon from '../../assets/images/message.svg?react';
import MoneyIcon from '../../assets/images/money.svg?react';
import NotFoundIcon from '../../assets/images/not-found.svg?react';
import AverageIcon from '../../assets/images/payments/average.svg?react';
import CoinsIcon from '../../assets/images/payments/coins.svg?react';
import DepositsEmptyStateIcon from '../../assets/images/payments/depositsEmptyState.svg?react';
import PaymentsEmptyStateIcon from '../../assets/images/payments/paymentsEmptyState.svg?react';
import RegisterIcon from '../../assets/images/payments/register.svg?react';
import PendingDishIcon from '../../assets/images/pending-dish.svg?react';
import PotIcon from '../../assets/images/pot.svg?react';
import ReceiptIcon from '../../assets/images/receipt.svg?react';
import SearchIcon from '../../assets/images/search.svg?react';
import ShareIcon from '../../assets/images/share.svg?react';
import ErrorIcon from '../../assets/images/small-close.svg?react';
import SodexoIcon from '../../assets/images/sodexo.svg?react';
import StatusIcon from '../../assets/images/status.svg?react';
import TableIcon from '../../assets/images/table.svg?react';
import TicketIcon from '../../assets/images/ticket.svg?react';
import TrashIcon from '../../assets/images/trash.svg?react';
import VisaIcon from '../../assets/images/visa.svg?react';
import VRIcon from '../../assets/images/vr.svg?react';
import Bank from '../../assets/images/zoop-onboarding/bank.svg?react';
import Business from '../../assets/images/zoop-onboarding/business.svg?react';
import CheckBrokenIcon from '../../assets/images/zoop-onboarding/check-broken.svg?react';
import UserProfiles from '../../assets/images/zoop-onboarding/users-profiles.svg?react';

const ICONS = {
  delete: DeleteIcon,
  error: ErrorIcon,
  import: ImportIcon,
  informative: InformativeIcon,
  success: CheckIcon,
  check: CheckTransparentIcon,
  copy: CopyIcon,
  warning: WarningIcon,
  calendar: CalendarIcon,
  eraser: EraserIcon,
  chevronDown: ChevronDownIcon,
  clock: ClockIcon,
  emptyState: EmptyStateIcon,
  money: MoneyIcon,
  visa: VisaIcon,
  master: MasterIcon,
  amex: AmexIcon,
  cabal: CabalIcon,
  hipercard: HipercardIcon,
  elo: EloIcon,
  alelo: AleloIcon,
  ticket: TicketIcon,
  sodexo: SodexoIcon,
  vr: VRIcon,
  register: RegisterIcon,
  average: AverageIcon,
  coins: CoinsIcon,
  paymentsEmptyState: PaymentsEmptyStateIcon,
  depositsEmptyState: DepositsEmptyStateIcon,
  notFound: NotFoundIcon,
  receipt: ReceiptIcon,
  export: ExportIcon,
  close: CloseIcon,
  chevron: ChevronIcon,
  search: SearchIcon,
  filter: FilterIcon,
  userProfiles: UserProfiles,
  business: Business,
  checkBroken: CheckBrokenIcon,
  bank: Bank,
  back: BackIcon,
  book: BookIcon,
  add: AddIcon,
  share: ShareIcon,
  expand: ExpandIcon,
  status: StatusIcon,
  table: TableIcon,
  pot: PotIcon,
  message: MessageIcon,
  pendingDish: PendingDishIcon,
  fastForward: FastForwardIcon,
  commanded: CommandedIcon,
  inProgressDish: InProgressDishIcon,
  link: LinkIcon,
  filtersLines: FiltersLinesIcon,
  edit: EditIcon,
  trash: DeleteIcon,
  clip: ClipIcon,
  clipboard: ClipboardIcon,
  trashRed: TrashIcon,
};

export type FudoIconProps = {
  type: keyof typeof ICONS;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'unset';
  sizePx?: number;
  fill?: ColorValues;
  stroke?: ColorValues;
};

export const FudoIcon = ({ type, size = 'xl', sizePx, fill, stroke }: FudoIconProps) => {
  const Icon = ICONS[type];

  return (
    <div
      className={classNames(`fudo-icon fudo-icon--${type} ${size}`, {
        [`fudo-icon-fill--${fill}`]: fill,
        [`fudo-icon-stroke--${stroke}`]: stroke,
      })}
    >
      {Icon && <Icon id={type} style={{ ...(sizePx ? { width: sizePx, height: sizePx } : {}) }} />}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

export interface FudoButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  text?: string;
  type?: 'button' | 'submit';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'transparent' | 'text';
  status?: 'success' | 'informative' | 'error' | 'warning' | 'default';
}

export const FudoButton: React.FC<FudoButtonProps> = ({
  fullWidth,
  icon,
  loading,
  text,
  type = 'button',
  variant = 'primary',
  status,
  className,
  ...props
}) => (
  <button
    {...props}
    type={type}
    className={classNames(
      'fudo-button',
      `fudo-button--${variant}`,
      {
        [`fudo-button--${variant} ${status}`]: status && variant !== 'text',
        loading: loading,
        'fudo-button--full-width': fullWidth,
      },
      className,
    )}
  >
    {icon && <>{icon}</>} {text}
  </button>
);
